import xhr from './xhr/';

// 量化管理-运动类相关API
class ReportSportsService {
	getHeadInfo(params) {
		return xhr({
			method: 'post',
			url: '/lh/head',
			params,
		});
	}
	// 报告结果
	getReportData(params) {
		return xhr({
			method: 'post',
			url: '/lh/yinyang',
			params,
		});
	}
	// 周运动评价
	weekRate(params) {
		return xhr({
			method: 'post',
			url: '/lh/wtxz',
			params,
		});
	}
	// 获取疾病
	getDisease(params) {
		return xhr({
			method: 'post',
			url: '/lh/getjb',
			params,
		});
	}
	// 获取时间
	getDietTime(params) {
		return xhr({
			method: 'post',
			url: '/lh/ssjl',
			params,
		});
	}
	// 问题汇总-运动问题
	questionSports(params) {
		return xhr({
			method: 'post',
			url: '/lh/ydwt',
			params,
		});
	}
	// 完成报告
	finishReport(params) {
		return xhr({
			method: 'post',
			url: '/lh/tjbg',
			params,
		});
	}

	// 获取某天血糖数据
	getSugarList(params) {
		return xhr({
			method: 'post',
			url: '/fxpg/sugar',
			params,
		});
	}

	// 生成血糖报告
	getSugarReport(params) {
		return xhr({
			method: 'post',
			url: '/fxpg/xtbg/get',
			params,
		});
	}

	getXtsjList(params) {
		return xhr({
			method: 'post',
			url: '/fxpg/xtsj',
			params,
		});
	}

	// 生成血压报告
	createPressureReport(params) {
		return xhr({
			method: 'post',
			url: '/fxpg/xybg/get',
			params,
		});
	}

	// 获取血压有效时间
	getPressureDate(params) {
		return xhr({
			method: 'post',
			url: '/fxpg/xysj',
			params,
		});
	}

	// 获取血压数据
	getPressureData(params) {
		return xhr({
			method: 'post',
			url: '/fxpg/blood',
			params,
		});
	}
	// 获取sun数据
	getSunData(params) {
		return xhr({
			method: 'post',
			url: '/fxpg/sun',
			params,
		});
	}
}

// 实例化后导出，全局单例
export default new ReportSportsService();
